export default `
query categories{
    categories(
     filters: {
        category_uid:{in:["NzI=", "ODI=", "ODc=", "ODQ=","NzQ=","NzY=","MjEz","ODY=","MjEx","NDM0"]}
      }
    ) {
      items {
        uid
        level
        name
        path
        url_path
        url_key
      }
    }
  }
`;
