var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hero"},[(_vm.sliderItems.length)?_c('Slider',{staticClass:"hero-carousel",attrs:{"data-cy":"hero-slider-carousel","slidesCount":_vm.sliderItems.length,"settings":{
      type: 'carousel',
      perView: 1,
      perTouch: 1,
      gap: 0,
      autoplay: 3000,
      rewind: false,
      bullets: true,
      peek : {
        before : 0,
        after : 0
      }
    }},scopedSlots:_vm._u([{key:"bullets",fn:function(){return undefined},proxy:true}],null,false,4186551356)},_vm._l((_vm.sliderItems),function(sliderItem,i){return _c('SliderItem',{key:i,staticClass:"hero-carousel__item"},[_c('SfLink',{attrs:{"link":sliderItem.link}},[_c('picture',[_c('source',{attrs:{"media":"(min-width:769px)","srcset":sliderItem.imageSrc}}),_vm._v(" "),_c('source',{attrs:{"media":"(max-width:768px)","srcset":sliderItem.mobileImageSrc}}),_vm._v(" "),_c('nuxt-img',{attrs:{"format":"webp","quality":"80","src":sliderItem.imageSrc,"alt":sliderItem.title,"width":sliderItem.imageWidth,"height":sliderItem.imageHeight}})],1)])],1)}),1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }