import { ref, onMounted, useContext } from '@nuxtjs/composition-api';
import categories from './categories.gql';

export async function useHomeCategories() {
  const homeCategories = ref();
  const { app } = useContext();
  try {
    const { data, loading } = await app.context.$vsf.$magento.api.customQuery({
      query: categories,
    });
    homeCategories.value = data?.categories?.items;
  } catch (err) {
    homeCategories.value = null;
  }

  return {
    homeCategories,
  };
};

export default useHomeCategories;