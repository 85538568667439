
import { parse } from 'node-html-parser';

export const useExtractSliderImages = (content) => {
  const root = parse(content);
  const sliderElements = root.querySelectorAll('div[data-content-type="slide"] div[data-background-type="image"]');
  const sliderContentElements = root.querySelectorAll('div[data-content-type="slide"]');
  const sliderLinkElements = root.querySelectorAll('div[data-content-type="slide"]');
  const sliderButtonElements = root.querySelectorAll('div[data-content-type="slide"]');

  let sliderItems = [];
  sliderElements.forEach((element, index) => {
    let singleImageDetails = JSON.parse(element.getAttribute('data-background-images').replace(/\\/g, ''));
    let content = sliderContentElements[index]?.querySelector('div[data-element="content"] p')?.innerHTML || '';
    let link = sliderLinkElements[index]?.querySelector('a[data-element="link"]')?.getAttribute('href') || '';
    let buttonText = sliderButtonElements[index]?.querySelector('button[data-element="button"]')?.innerHTML || '';
    let singleSliderDetails = {
      image_details: singleImageDetails,
      meta_details: {
        title: content,
        link: link,
        button_text: buttonText,
        index: index,
      }
    }
    sliderItems = [...sliderItems, singleSliderDetails];
  });
  return sliderItems;
};

export default useExtractSliderImages;