

















import {
  defineComponent,
  ref,
  ssrRef,
  useContext,
  onMounted,
  useFetch,
} from '@nuxtjs/composition-api';
import { onSSR } from '@vue-storefront/core';
import { useCache, CacheTagPrefix } from '@vue-storefront/cache';
import { SfBanner, SfBannerGrid } from '@storefront-ui/vue';
import { CmsPage } from '~/modules/GraphQL/types';
import { getMetaInfo } from '~/helpers/getMetaInfo';
import { useContent } from '~/composables';
import { useCmsBlock } from '~/composables/useCmsBlock';
import { useHomeCategories } from '~/composables/useHomePage/useHomeCategories';
import { useCmsBlocksStore } from '~/stores/cmsBlocks';
import LazyHydrate from 'vue-lazy-hydration';
import HeroSection from '~/components/HeroSection.vue';
import RenderComponents from '~/layouts/components.vue';
import useExtractSliderImages from '~/composables/useCmsBlock/useExtractSliderImages';
import { getHomePageCmsBlocksDetails } from '~/helpers/getHomePageCmsBlocksDetails';

export default defineComponent({
  name: 'HomePage',
  components: {
    HeroSection,
    LazyHydrate,
    SfBanner,
    SfBannerGrid,
    RenderComponents,
    AvailableEmis: () => import('~/components/AvailableEmis.vue'),
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup() {
    const { addTags } = useCache();
    const { loadPage } = useContent();
    const { app } = useContext();
    const { isDesktop } = app.$device;
    const hero = ssrRef([]);
    const categoryDetails = ref([]);
    const componentsList = ref([]);
    const page = ref<CmsPage | null>(null);
    const cmsBlocksStore = useCmsBlocksStore();

    onSSR(async () => {
      // Await the execution of asynchronous tasks
      const categoryDetailsObject = await getCategoryDetails();
      const componentsListData = await getHomePageCmsBlocksDetails(categoryDetailsObject);
      addTags([
        { prefix: CacheTagPrefix.View, value: 'home' }
      ]);
    });
    useFetch(async () => {
      const [categoryDetailsObject] = await Promise.all([
        getCategoryDetails(),
      ]);

      categoryDetails.value = categoryDetailsObject;
      componentsList.value = getHomePageCmsBlocksDetails(categoryDetailsObject);
    });

    useFetch(async () => {
      page.value = await loadPage({ identifier: 'home' });
    });

    /**
     * Get banner slider data.
     *
     * @return array
     */
    async function getHomePageSliderDetails() {
      const blockData = cmsBlocksStore.blocks.cmsBlocks.find(block => block.identifier === "hero-slider");
      let sliderImages = useExtractSliderImages(blockData?.content);

      let heroSliderDetails = [];
      sliderImages.forEach(sliderItem => {
        let singleItem = {
          title: app.i18n.t(sliderItem?.meta_details?.title),
          buttonText: app.i18n.t(sliderItem?.meta_details?.button_text),
          imageSrc: sliderItem?.image_details?.desktop_image,
          mobileImageSrc: sliderItem?.image_details?.mobile_image,
          imageWidth: isDesktop ? 1240 : 328,
          imageHeight: isDesktop ? 400 : 224,
          imageConfig: {
            fit: 'contain',
            format: 'webp',
          },
          link: sliderItem?.meta_details?.link,
        }
        heroSliderDetails = [...heroSliderDetails, singleItem];
      });

      return heroSliderDetails;
    };

    /**
     * Get  categories for home page content redering.
     *
     * @return array
     */
    async function getCategoryDetails() {
      const { homeCategories } = await useHomeCategories();
      return homeCategories.value;
    };

    onMounted(async () => {
      //addTags([{ prefix: CacheTagPrefix.View, value: 'home' }]);

      hero.value = await getHomePageSliderDetails();
    });

    // @ts-ignore
    return {
      hero,
      page,
      componentsList,
    };
  },
  head() {
    return getMetaInfo(this.page);
  },
});
