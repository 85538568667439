





















































import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { ImageModifiers } from '@nuxt/image';
import { SfButton, SfImage, SfLink } from '@storefront-ui/vue';

export default defineComponent({
  name: 'HeroSection',
  components: {
    SfButton,
    SfImage,
    SfLink,
    Slider: () => import("~/components/Custom/Organisms/Slider.vue"),
  },
  props: {
    sliderItems: {
      type: Array,
      default: [],
    },
    nuxtImgConfig: {
      type: Object as PropType<ImageModifiers | {}>,
      default: () => ({}),
    },
  },
  
});
