import { SortEnum } from '~/modules/GraphQL/types';
import {
  createProductAttributeFilterInput
} from
  '~/modules/catalog/category/composables/useFacet/input/createProductAttributeFilterInput';

export const getHomePageCmsBlocksDetails = (categoryDetails) => {

  /**
   * Get meta information of a given category.
   *
   * @param catUId: string
   * @param detailType: string
   *
   * @return array
   */
  function getCategoryMetaDetail(catUId: string, detailType: string) {
    return categoryDetails?.filter(cat => cat.uid === catUId)[0]?.[detailType];
  }

  /**
   * Get detail page links for a given category.
   * 
   * @param link: string
   *
   * @return string
   */
  function getCategoryLink(link: string) {
    let actualLink = 'undefined' === typeof link ? '' : `${link}.html`;
    return actualLink.startsWith('/') ? actualLink : `/${actualLink}`;
  }

  return [
    {
      type: 'products',
      className: 'new-products',
      buttonText: 'View All',
      title: 'New Arrivals',
      link: '/new-products?new=1',
      ads: {
        position: 'ads__right',
        className: 'side-banner',
        link: '',
        identifier: 'ads-block-1'
      },
      filter: {
        new: {
          eq: "1"
        },
      },
      sort: {}
    },
    {
      type: 'products',
      className: 'bigdeal-products',
      buttonText: 'View All',
      title: 'Big Deal Products',
      link: '/big-deal-products?page=0&special_price=0_100000000',
      ads: {
        position: 'ads__right',
        className: 'side-banner',
        link: '',
        identifier: 'ads-block-2'
      },
      filter: {
        special_price: {
          from: "0",
          // needs maximum higher value, according to doc this is not needed , but it will not work if "to" value if not present
          to: "100000000"
        }
      },
      sort: {
        special_price: SortEnum.Desc,
      },
    },
    {
      type: 'cms-block',
      className: 'wide-ads-banner',
      link: 'google.com',
      identifier: 'banner-block-1'
    },
    {
      type: 'products',
      className: 'category-products',
      buttonText: 'View All',
      title: getCategoryMetaDetail('NzI=', 'name'),
      link: getCategoryLink(getCategoryMetaDetail('NzI=', 'url_path')),
      ads: {
        position: 'ads__left',
        className: 'side-banner',
        link: '/men.html',
        identifier: 'ads-block-3'
      },
      filter: createProductAttributeFilterInput({ category_uid: 'NzI=' }),
      sort: {}
    },
    {
      type: 'products',
      className: 'category-products',
      buttonText: 'View All',
      title: getCategoryMetaDetail('ODI=', 'name'),
      link: getCategoryLink(getCategoryMetaDetail('ODI=', 'url_path')),
      ads: {
        position: 'ads__left',
        className: 'side-banner',
        link: '/men/tops-men/tees-men.html',
        identifier: 'ads-block-4'
      },
      filter: createProductAttributeFilterInput({ category_uid: 'ODI=' }),
      sort: {}
    },
    {
      type: 'cms-block',
      className: 'wide-ads-banner',
      link: 'google.com',
      identifier: 'banner-block-2'
    },
    {
      type: 'products',
      className: 'category-products',
      buttonText: 'View All',
      title: getCategoryMetaDetail('ODc=', 'name'),
      link: getCategoryLink(getCategoryMetaDetail('ODc=', 'url_path')),
      ads: {
        position: 'ads__right',
        className: 'side-banner',
        link: '/women/tops-women/jackets-women.html',
        identifier: 'ads-block-5'
      },
      filter: createProductAttributeFilterInput({ category_uid: 'ODc=' }),
      sort: {}
    },
    {
      type: 'products',
      className: 'category-products',
      buttonText: 'View All',
      title: getCategoryMetaDetail('ODQ=', 'name'),
      link: getCategoryLink(getCategoryMetaDetail('ODQ=', 'url_path')),
      ads: {
        position: 'ads__right',
        className: 'side-banner',
        link: '/gear/bags.html',
        identifier: 'ads-block-6'
      },
      filter: createProductAttributeFilterInput({ category_uid: 'ODQ=' }),
      sort: {}
    },
    {
      type: 'cms-block',
      className: 'wide-ads-banner',
      link: 'google.com',
      identifier: 'banner-block-3'
    },
    {
      type: 'products',
      className: 'category-products',
      buttonText: 'View All',
      title: getCategoryMetaDetail('NzQ=', 'name'),
      link: getCategoryLink(getCategoryMetaDetail('NzQ=', 'url_path')),
      ads: {
        position: 'ads__left',
        className: 'side-banner',
        link: '/gear/bags.html',
        identifier: 'ads-block-mobile-tablets'
      },
      filter: createProductAttributeFilterInput({ category_uid: 'NzQ=' }),
      sort: {}
    },
    {
      type: 'products',
      className: 'category-products',
      buttonText: 'View All',
      title: getCategoryMetaDetail('NzY=', 'name'),
      link: getCategoryLink(getCategoryMetaDetail('NzY=', 'url_path')),
      ads: {
        position: 'ads__left',
        className: 'side-banner',
        link: '/gear/bags.html',
        identifier: 'ads-block-computer-peripherals'
      },
      filter: createProductAttributeFilterInput({ category_uid: 'NzY=' }),
      sort: {}
    },
    {
      type: 'cms-block',
      className: 'wide-ads-banner',
      link: 'google.com',
      identifier: 'banner-after-computer-peripherals'
    },
    {
      type: 'products',
      className: 'category-products',
      buttonText: 'View All',
      title: getCategoryMetaDetail('MjEz', 'name'),
      link: getCategoryLink(getCategoryMetaDetail('MjEz', 'url_path')),
      ads: {
        position: 'ads__left',
        className: 'side-banner',
        link: '/gear/bags.html',
        identifier: 'ads-block-wearables'
      },
      filter: createProductAttributeFilterInput({ category_uid: 'MjEz' }),
      sort: {}
    },
    {
      type: 'products',
      className: 'category-products',
      buttonText: 'View All',
      title: getCategoryMetaDetail('ODY=', 'name'),
      link: getCategoryLink(getCategoryMetaDetail('ODY=', 'url_path')),
      ads: {
        position: 'ads__right',
        className: 'side-banner',
        link: '/gear/bags.html',
        identifier: 'ads-block-accessories'
      },
      filter: createProductAttributeFilterInput({ category_uid: 'ODY=' }),
      sort: {}
    },
    {
      type: 'cms-block',
      className: 'wide-ads-banner',
      link: 'google.com',
      identifier: 'banner-after-accessories'
    },
    {
      type: 'products',
      className: 'category-products',
      buttonText: 'View All',
      title: getCategoryMetaDetail('MjEx', 'name'),
      link: getCategoryLink(getCategoryMetaDetail('MjEx', 'url_path')),
      ads: {
        position: 'ads__right',
        className: 'side-banner',
        link: '/gear/bags.html',
        identifier: 'ads-block-personal-care'
      },
      filter: createProductAttributeFilterInput({ category_uid: 'MjEx' }),
      sort: {}
    },
    {
      type: 'products',
      className: 'category-products',
      buttonText: 'View All',
      title: getCategoryMetaDetail('NDM0', 'name'),
      link: getCategoryLink(getCategoryMetaDetail('NDM0', 'url_path')),
      ads: {
        position: 'ads__right',
        className: 'side-banner',
        link: '/gear/bags.html',
        identifier: 'ads-block-kitchen-appliances'
      },
      filter: createProductAttributeFilterInput({ category_uid: 'NDM0' }),
      sort: {}
    },
    
  ]
}
